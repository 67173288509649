<template>
  <div class="row">
    <div
      class="col table-responsive"
      v-if="recruiterFollowingPlayer.length"
    >
      <table class="table table-sm table-hover table-striped">
        <thead class="table-header">
          <tr>
            <th
              scope="col-1"
              class="fs-7 py-2 px-3"
            >
              Full Name
            </th>
            <th
              scope="col-3"
              class="fs-7 py-2 px-3"
            >
              Position
            </th>
            <th
              scope="col-2"
              class="fs-7 py-2 px-3"
            >
              Name
            </th>
            <th
              scope="col-3"
              class="fs-7 py-2 px-3"
            >
              Class
            </th>
            <th
              scope="col-2"
              class="fs-7 py-2 px-3"
            >
              Phone
            </th>
            <th
              scope="col-2"
              class="fs-7 py-2 px-3"
            >
              Email
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="user in recruiterFollowingPlayer"
            :key="user.id"
          >
            <td
              scope="col-1"
              class="fs-7 py-2 px-3"
            >
              <div class="d-flex justify-content-start align-items-center">
                <img
                  :src="user.photo ? user.photo : '/images/team-player-profile.jpg'"
                  width="40"
                />
                <router-link
                  :to="`player-detail/${user.id}`"
                  class="btn btn-sm btn-link"
                >
                  <div class="ms-1">{{ user.firstName }}{{ user.middleName }} {{ user.lastName }}</div></router-link
                >
              </div>
            </td>
            <td
              scope="col-3"
              class="fs-7 py-2 px-3"
            >
              <div class="d-flex justify-content-start align-items-center">
                <span class="py-2">{{ Array.isArray(user.position) ? user.position.join(" ") : "" }}</span>
              </div>
            </td>
            <td
              scope="col-2"
              class="fs-7 py-2 px-3"
            >
              <div class="d-flex justify-content-start align-items-center">
                <span class="py-2"> {{ user.firstName }} {{ user.lastName }} </span>
              </div>
            </td>
            <td
              scope="col-3"
              class="fs-7 py-2 px-3"
            >
              <div class="d-flex justify-content-start align-items-center">
                <span class="py-2"> {{ typeof user.classOf == "string" && user.classOf != "" ? user.classOf.split("-")[0] : "" }} </span> .
              </div>
            </td>
            <td
              scope="col-2"
              class="fs-7 py-2 p-3"
            >
              <div class="d-flex justify-content-start align-items-center">
                <span class="py-2"> {{ user.phone }} </span>
              </div>
            </td>
            <td
              scope="col-2"
              class="fs-7 py-2 p-3"
            >
              <div class="d-flex justify-content-start align-items-center">
                <span class="py-2"> {{ user.email }} </span>
              </div>
            </td>
            <td
              scope="col-1"
              class="fs-7 py-2 p-3"
            >
              <div class="d-flex justify-content-start align-items-center">
                <span
                  class="py-2 cursor-pointer"
                  @click.stop="handleRemoveItems(user)"
                >
                  X
                </span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      v-else
      class="d-flex justify-content-center align-items-center"
    >
      <span>No Data Available</span>
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref } from "vue";
import { auth, db } from "../../firebase";
import { collection, getDocs, query, where, doc, getDoc, updateDoc } from "firebase/firestore";

onMounted(() => {
  loadRecruiterLikeList();
});
const recruiterFollowingPlayer = ref("");
const recruiterFollowingPlayerList = ref("");
const loadRecruiterLikeList = async () => {
  const userRef = doc(db, "profiles", auth.currentUser?.uid);
  const userProfile = await getDoc(userRef);
  if (userProfile.data().hasOwnProperty("followingPlayers")) {
    recruiterFollowingPlayerList.value = [...userProfile.data().followingPlayers];
    loadRecruiterLikeData(recruiterFollowingPlayerList.value);
  }
};
const loadRecruiterLikeData = async (ListData) => {
  const promisData = ListData.map(async (currentEle) => {
    const userRef = doc(db, "players", currentEle);
    return await getDoc(userRef);
  });
  let userData = await Promise.all(promisData);
  recruiterFollowingPlayer.value = userData.map((ele) => {
    return {
      ...ele.data(),
      id: ele.id,
    };
  });
};
const handleRemoveItems = async (currentUser) => {
  if (recruiterFollowingPlayerList.value.includes(currentUser.id)) {
    let findIndex = recruiterFollowingPlayerList.value.indexOf(currentUser.id);
    if (findIndex !== -1) {
      recruiterFollowingPlayerList.value.splice(findIndex, 1);
      //***Updating The Array In The DataBase of Profile***///
      await updateDoc(doc(db, "profiles", auth.currentUser?.uid), {
        followingPlayers: [...recruiterFollowingPlayerList.value],
      });

      //***Updating The Array In The Local of Profile list***///
      let index = recruiterFollowingPlayer.value.findIndex((cur) => cur.id == currentUser.id);
      if (index !== -1) {
        recruiterFollowingPlayer.value.splice(index, 1);
      }
    }

    //***Updating The Array In The DataBase of Player***///
    // let followingRecruiterArray = [...currentUser.followingRecruiter];
    // let _findIndex = followingRecruiterArray.findIndex((cur) => cur.userId == currentUser.id);
    const userRef = query(collection(db, "profiles"), where("email", "==", currentUser.email));
    const userProfile = await getDocs(userRef);
    let loadObjectData = "";
    let loadObjectDataId = "";
    if (userProfile.docs[0]?.data()) {
      loadObjectData = userProfile.docs[0].data();
      loadObjectDataId = userProfile.docs[0].id;
      if (loadObjectData.hasOwnProperty("playerFollowingByRecruiter")) {
        let playerFollowingByRecruiterArray = loadObjectData.playerFollowingByRecruiter;
        let index = playerFollowingByRecruiterArray.findIndex((cur) => cur.userId == auth.currentUser?.uid);
        playerFollowingByRecruiterArray.splice(index, 1);
        await updateDoc(doc(db, "profiles", loadObjectDataId), {
          playerFollowingByRecruiter: [...playerFollowingByRecruiterArray],
        });
      }
    }
  }
};
</script>
<style scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
