<template>
  <router-link
    v-show="isOnlyShowUserTeams"
    to="/superadmin-dashboard/users"
    class="btn btn-link ps-0 me-2"
    ><font-awesome-icon
      :icon="['fas', 'arrow-left']"
      class="me-2"
    />Back</router-link
  >
  <div class="d-flex justify-content-between pb-2">
    <div class="mb-2">
      <h3>Teams</h3>
    </div>
    <div
      v-if="canCreate && !loading && !isOnlyShowUserTeams"
      class="ms-3"
    >
      <router-link
        to="/add-team"
        class="btn btn-primary"
        >+ Add Team</router-link
      >
    </div>
  </div>
  <div
    class="card mb-2"
    v-if="!isOnlyShowUserTeams"
  >
    <div class="card-body d-flex">
      <div class="me-2">
        <input
          type="text"
          class="form-control mb-1 text-muted"
          placeholder="Search for a team (name, gender, club...)"
          v-model="searchQuery"
          @input="searchTeam"
        />
      </div>

      <div class="ms-auto d-flex justify-content-center items align-items-center">
        <div class="text-end text-muted">
          <small>Total Teams: {{ totolNumberOfRows }}</small>
        </div>
      </div>
    </div>
  </div>

  <div class="card border-light">
    <div class="card-header align-content-center border-light">
      <div
        class="btn-group"
        v-if="!isOnlyShowUserTeams"
      >
        <input
          type="radio"
          class="btn-check"
          name="btnradio"
          id="btnradio1"
          autocomplete="off"
          checked
          @change="teamFilter(true)"
        />
        <label
          class="btn btn-sm btn-outline-primary"
          for="btnradio1"
          >All Teams</label
        >

        <input
          type="radio"
          class="btn-check btn-sm"
          name="btnradio"
          id="btnradio2"
          autocomplete="off"
          @change="teamFilter(false)"
        />
        <label
          class="btn btn-sm btn-outline-primary"
          for="btnradio2"
          >My Teams</label
        >
      </div>
      <div v-else>
        <h3>{{ `${loadUserFromParamEmail} (${totolNumberOfRows})` }}</h3>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col">
          <!-- loader -->
          <div
            v-for="n in 6"
            v-if="loading && !teams.length"
          >
            <PreLoaderComponent heightPx="130px" />
          </div>
          <div
            class="card mb-2 card-list-decoration no-deco"
            v-if="paginatedProfiles.length"
            v-for="team in paginatedProfiles"
            :key="team.id"
          >
            <div class="card-body">
              <div class="row">
                <div class="col-md-1 col-sm-2 d-flex align-items-center">
                  <img
                    :src="team.clubLogo || '/images/club-logo-default-thumb.jpg'"
                    alt="Club Logo"
                    width="70"
                    height="70"
                  />
                </div>
                <div class="col-md-3 col-sm-10 pb-2">
                  <router-link :to="{ name: 'TeamManage', params: { teamId: team.id } }">
                    <strong
                      >{{ team?.leagueShortName }} {{ team.clubShortName }}
                      {{ team.state ? "(" + team.state + ")" : "" }}
                      {{ team.ageGroup }} - {{ team.gender }}</strong
                    >
                  </router-link>

                  <br />
                  <span class="mini-label">Team Alias: {{ team.name }}</span
                  ><br />
                  <span class="mini-label">League: {{ team.leagueName }}</span>
                  <br />
                  <span class="mini-label">Club Region: {{ team.regionName }}</span>
                </div>
                <div class="col-sm-2 col-md-3 d-flex-column align-items-center pb-2">
                  <div class="mini-label top">Age Group</div>
                  {{ team.ageGroup }}
                </div>

                <div class="col-sm-2 col-md-2 d-flex-column align-items-center pb-2">
                  <div class="mini-label top">Player Count</div>
                  <div>
                    <router-link :to="{ name: 'TeamManage', params: { teamId: team.id } }">{{ team.playerCount || 0 }} players</router-link>
                  </div>
                </div>
                <small
                  v-if="canEdit || canDelete || canAddPlayer || checkIfUserIdMatchWithTeamOwn(userId, team.ownerId)"
                  class="col-md-3 col-sm-3 d-flex-column align-items-center justify-content-end pb-2 text-end"
                >
                  <div class="mini-label top">Actions</div>
                  <a
                    v-if="canAddPlayer || checkIfUserIdMatchWithTeamOwn(userId, team.ownerId)"
                    href="#"
                    class="ms-2"
                    @click.prevent="showAddPlayerModalFunc(team.id)"
                    >+ Player</a
                  >
                  <span class="mx-2 text-secondary">|</span>
                  <router-link
                    v-if="canEdit || checkIfUserIdMatchWithTeamOwn(userId, team.ownerId)"
                    :to="{ name: 'EditTeam', params: { teamId: team.id } }"
                    class="icon-link"
                    ><font-awesome-icon :icon="['fas', 'pen']"
                  /></router-link>
                  <span class="mx-2 text-secondary">|</span>
                  <a
                    v-if="canDelete || checkIfUserIdMatchWithTeamOwn(userId, team.ownerId)"
                    href="#"
                    class="px-2 icon-link"
                    @click.prevent="showDeleteConfirmation(team)"
                    ><font-awesome-icon
                      :icon="['fas', 'trash']"
                      class="me-2"
                  /></a>
                </small>
                <small
                  v-else
                  class="col-md-3 col-sm-3 d-flex-column align-items-center justify-content-end text-muted pb-2 text-end"
                >
                  <div class="mini-label top">Actions</div>
                  No Actions
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card border-light">
      <div class="card-body d-flex">
        <div class="">
          <small class="text-muted">Total Teams: {{ totolNumberOfRows }}</small>
        </div>

        <div
          class="ms-auto"
          v-if="totolNumberOfRows > 25 && checkPaginationShowOrNotOnFilter"
        >
          <nav aria-label="...">
            <ul class="pagination mb-0">
              <li
                class="page-item"
                :class="[selectedPaginationPage == 1 ? 'disabled not-allowed' : '']"
                @click.stop="handleNuxtAndPreviousButton('pre')"
              >
                <a
                  class="page-link cursor"
                  href="#"
                  tabindex="-1"
                  aria-disabled="true"
                  >Previous</a
                >
              </li>
              <li
                class="page-item"
                v-for="n in paginationPages"
                :class="[selectedPaginationPage == n ? 'active' : '']"
                @click.stop="onChangePagination(n)"
              >
                <a class="page-link cursor">{{ n }}</a>
              </li>
              <li
                class="page-item"
                :class="[selectedPaginationPage == paginationPages ? 'disabled not-allowed' : '']"
              >
                <a
                  class="page-link cursor"
                  @click.stop="handleNuxtAndPreviousButton('next')"
                  >Next</a
                >
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>

  <div v-if="!teams.length && !loading && !searchQuery">
    <div class="alert alert-info">
      <p>There are no teams, add your team to start.</p>
    </div>
  </div>
  <div v-if="!teams.length && !loading && searchQuery">
    <div class="alert alert-info">No teams found for your search.</div>
  </div>

  <!-- Modal for Deleting Team -->
  <ModalComponent
    v-model="showModal"
    header="Confirm Deleting the Team"
  >
    <template #default>
      <div>
        <p v-if="selectedTeam && selectedTeam.playerCount > 0">You cannot delete this team because it has associated players. Delete the players first before you can delete the team.</p>
        <p
          v-else
          class="text-center"
        >
          Are you sure you want to delete this team?
        </p>
      </div>
      <div>
        <button
          class="btn btn-sm btn-secondary-outline me-2"
          @click="showModal = false"
        >
          No, Cancel
        </button>
        <button
          class="btn btn-sm btn-danger"
          v-if="selectedTeam && selectedTeam.playerCount === 0"
          @click="deleteTeam"
        >
          Yes, Delete
        </button>
      </div>
    </template>
  </ModalComponent>

  <!-- Modal for Adding Player -->
  <ModalComponent
    v-model="showAddPlayerModal"
    header="Add Player to Team"
  >
    <PlayerFormComponent
      :teamId="selectedTeamId"
      @playerAdded="onPlayerAdded"
      @closeModal="handleCloseAddPlayerModal"
    />
  </ModalComponent>
</template>
<script>
import { ref, computed, onMounted } from "vue";
import PlayerFormComponent from "./PlayerFormComponent.vue";
import ModalComponent from "./ModalComponent.vue";
import { db, auth } from "../firebase";
import PreLoaderComponent from "@/components/PreLoaderComponent.vue";
import { collection, getDocs, query, where, doc, getDoc, deleteDoc } from "firebase/firestore";
import { useToast } from "vue-toastification";
import { onAuthStateChanged } from "firebase/auth";
import { getUserRole } from "../utils/auth";
import ROLES from "../utils/roles.json";
import { useRouter, useRoute } from "vue-router";

export default {
  components: {
    PlayerFormComponent,
    ModalComponent,
    PreLoaderComponent,
  },

  props: {
    ownerId: {
      type: [String, null],
      default: null,
    },
  },
  setup() {
    const teams = ref([]);
    const allTeams = ref([]);
    const selectedTeam = ref(null);
    const showModal = ref(false);
    const loading = ref(true);
    const selectedTeamId = ref(null);
    const showAddPlayerModal = ref(false);
    const userRole = ref("");
    const paginationSize = 50; // Number of items per page
    const paginationPages = ref(1);
    const selectedPaginationPage = ref(1);
    const totolNumberOfRows = ref("");
    const loadUserFromParamEmail = ref("");
    const canCreate = ref(false);
    const canEdit = ref(false);
    const canDelete = ref(false);
    const canViewAll = ref(false);
    const canViewDetailsAll = ref(false);
    const canAddPlayer = ref(false);
    const userId = auth.currentUser?.uid;
    const router = useRouter();
    const route = useRoute();
    // get search from url
    const searchQuery = ref(router.currentRoute.value.query.search || "");
    onMounted(() => {
      loadUserDataFromParam();
    });
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        userRole.value = await getUserRole(user);
        canViewAll.value = isOnlyShowUserTeams.value ? false : ROLES.TEAM.VIEWALL.includes(userRole.value?.toLowerCase());
        canCreate.value = ROLES.TEAM.CREATE.includes(userRole.value?.toLowerCase());
        canEdit.value = ROLES.TEAM.EDIT.includes(userRole.value?.toLowerCase());
        canDelete.value = ROLES.TEAM.DELETE.includes(userRole.value?.toLowerCase());
        canViewDetailsAll.value = ROLES.TEAM.VIEWDETAILSALL.includes(userRole.value?.toLowerCase());
        canAddPlayer.value = ROLES.PLAYER.CREATE.includes(userRole.value?.toLowerCase());
        fetchTeams();
      }
    });

    function searchTeam() {
      teams.value = allTeams.value.filter((team) => {
        return (
          team.name.toLowerCase().includes(searchQuery?.value?.toLowerCase()) ||
          team.shortName.toLowerCase().includes(searchQuery?.value?.toLowerCase()) ||
          team.gender.toLowerCase().includes(searchQuery?.value?.toLowerCase()) ||
          team.leagueName.toLowerCase().includes(searchQuery?.value?.toLowerCase()) ||
          team.clubShortName.toLowerCase().includes(searchQuery?.value?.toLowerCase()) ||
          team.leagueShortName.toLowerCase().includes(searchQuery?.value?.toLowerCase()) ||
          team.ageGroup?.toString().includes(searchQuery?.value?.toLowerCase()) ||
          team.club.includes(searchQuery?.value?.toLowerCase())
        );
      });
      // change url
      router.push({ query: { search: searchQuery.value } });
    }
    async function fetchTeams() {
      loading.value = true;
      const userId = auth.currentUser?.uid;
      if (!userId) return;
      let teamQuery;
      let arrayQuery = "";
      let arrayQuerySnapshot;
      let stringQuerySnapshot;
      let mergedQuerySnapshot;

      // getTotalNumberOfRows(); // get the total number of collection count on each call.

      try {
        let _userId = getParamsid.value ? getParamsid.value : userId; // check wheater team load for current user or other user.
        teamQuery = query(collection(db, "teams"), !canViewAll.value && where("ownerId", "==", _userId));
        arrayQuery = !canViewAll.value ? query(collection(db, "teams"), !canViewAll.value && where("ownerId", "array-contains", _userId)) : "";

        if (arrayQuery != "") {
          stringQuerySnapshot = await getDocs(teamQuery);
          arrayQuerySnapshot = await getDocs(arrayQuery);
          mergedQuerySnapshot = [...stringQuerySnapshot.docs, ...arrayQuerySnapshot.docs];
          totolNumberOfRows.value = mergedQuerySnapshot.length;
          paginationPages.value = Math.ceil(mergedQuerySnapshot.length / paginationSize);
        } else {
          stringQuerySnapshot = await getDocs(teamQuery);
          mergedQuerySnapshot = [...stringQuerySnapshot.docs];
          totolNumberOfRows.value = mergedQuerySnapshot.length;
          paginationPages.value = Math.ceil(mergedQuerySnapshot.length / paginationSize);
        }
        // Execute both queries concurrently

        //const teamSnapshots = await getDocs(teamQuery);

        const teamPromises = mergedQuerySnapshot.map(async (teamSnapshot) => {
          const teamData = teamSnapshot.data();

          // Get count of players associated with this team
          const playerQuery = query(collection(db, "players"), where("teamId", "==", teamSnapshot.id));
          const playerSnapshots = await getDocs(playerQuery);
          teamData.playerCount = playerSnapshots.size;

          if (teamData.club) {
            const clubSnapshot = await getDoc(doc(db, "clubs", teamData.club));
            if (clubSnapshot.exists()) {
              const clubData = clubSnapshot.data();
              teamData.clubShortName = clubData.shortName || "N/A";
              teamData.clubRegion = clubData.regionId || "N/A";
              teamData.clubLogo = clubData.logo || null;

              // Use the club's leagueId to get the league
              if (clubData.leagueId) {
                clubData.leagueId.map(async (id) => {
                  const leagueSnapshot = await getDoc(doc(db, "leagues", id));
                  if (leagueSnapshot.exists()) {
                    const leagueData = leagueSnapshot.data();
                    teamData.leagueName = leagueData.name || "N/A";
                    teamData.leagueShortName = leagueData.shortName || "N/A";
                  }
                });
              }

              // Use the club's regionId to get the region
              if (clubData.regionId) {
                const regionSnapshot = await getDoc(doc(db, "regions", clubData.regionId));
                if (regionSnapshot.exists()) {
                  const regionData = regionSnapshot.data();
                  teamData.regionName = regionData.name || "N/A";
                }
              }
            }
          }

          if (teamData.state) {
            const stateSnapshot = await getDoc(doc(db, "states", teamData.state));
            if (stateSnapshot.exists()) {
              const stateData = stateSnapshot.data();
              teamData.state = stateData.state || "N/A"; // Override the team's state ID with the actual state name
            }
          }

          return {
            id: teamSnapshot.id,
            ...teamData,
          };
        });

        teams.value = await Promise.all(teamPromises);
        allTeams.value = teams.value;
        searchTeam();
      } catch (error) {
        console.error("Error fetching teams:", error);
      } finally {
        loading.value = false;
      }
    }

    async function deleteTeam() {
      if (!selectedTeam.value) return;

      try {
        await deleteDoc(doc(db, "teams", selectedTeam.value.id));
        showModal.value = false;
        fetchTeams();
      } catch (error) {
        console.error("Error deleting team:", error);
      }
    }
    function checkIfUserIdMatchWithTeamOwn(userId, ownerId) {
      if (Array.isArray(ownerId)) {
        return ownerId.includes(userId);
      }
      return userId == ownerId;
    }
    function showDeleteConfirmation(team) {
      selectedTeam.value = team;
      showModal.value = true;
    }

    function onPlayerAdded() {
      showAddPlayerModal.value = false;
      fetchTeams();
    }

    function handleCloseAddPlayerModal() {
      showAddPlayerModal.value = false;
    }

    function showAddPlayerModalFunc(teamId) {
      selectedTeamId.value = teamId;
      showAddPlayerModal.value = true;
    }
    function clearTeamArray() {
      teams.value = [];
    }
    function teamFilter(showMyTeam) {
      canViewAll.value = showMyTeam;
      clearTeamArray();
      fetchTeams();
    }
    function onChangePagination(selectedNumber) {
      selectedPaginationPage.value = selectedNumber;
    }

    const handleNuxtAndPreviousButton = (action) => {
      if (action === "pre" && selectedPaginationPage.value > 1) {
        selectedPaginationPage.value--;
      } else if (action === "next" && selectedPaginationPage.value < paginationPages.value) {
        selectedPaginationPage.value++;
      }
    };
    const loadUserDataFromParam = async () => {
      if (route.params.team) {
        const userRef = doc(db, "profiles", getParamsid.value); // Referencing the user's profile using their uid
        const userProfile = await getDoc(userRef);
        loadUserFromParamEmail.value = userProfile.data().email;
      }
    };
    const paginatedProfiles = computed(() => {
      const startIndex = (selectedPaginationPage.value - 1) * paginationSize;
      const endIndex = startIndex + paginationSize;
      return teams.value.slice(startIndex, endIndex);
    });
    const checkPaginationShowOrNotOnFilter = computed(() => {
      if (searchQuery.value && teams.value.length < paginationSize) {
        return false;
      }
      return true;
    });
    const isOnlyShowUserTeams = computed(() => {
      return Array.isArray(route.params.team) ? route.params.team.length !== 0 : route.params.team !== "";
    });
    const getParamsid = computed(() => (Array.isArray(route.params.team) ? route.params.team[0] : route.params.team));
    return {
      auth,
      teams,
      selectedTeam,
      showModal,
      fetchTeams,
      showDeleteConfirmation,
      deleteTeam,
      showAddPlayerModalFunc,
      selectedTeamId,
      onPlayerAdded,
      showAddPlayerModal,
      handleCloseAddPlayerModal,
      canCreate,
      canEdit,
      canDelete,
      canViewDetailsAll,
      canAddPlayer,
      loading,
      userId,
      searchQuery,
      searchTeam,
      teamFilter,
      totolNumberOfRows,
      paginationPages,
      selectedPaginationPage,
      onChangePagination,
      handleNuxtAndPreviousButton,

      checkIfUserIdMatchWithTeamOwn,
      paginatedProfiles,
      checkPaginationShowOrNotOnFilter,
      isOnlyShowUserTeams,
      loadUserFromParamEmail,
    };
  },
};
</script>
<style lang="scss" scoped>
$gray-60: #777;

.no-deco {
  a {
    text-decoration: none;
  }

  .icon-link {
    color: #444 !important;
  }
}

.card-list-decoration {
  transition: all ease-in-out 0.5s;
  background-color: #f6f6f6;

  &:hover {
    background-color: #dae3eb;
    box-shadow: 1px 1px 4px #b7c7cd;
  }
}

.mini-label {
  color: $gray-60;
  font-size: 0.8rem;
}
.cursor {
  cursor: pointer;
}
.not-allowed {
  cursor: not-allowed !important;
}

::v-deep(.btn-group > .btn-check:checked + .btn) {
  z-index: 0;
}
</style>
