<template>
  <div class="m-0 p-0 position-relative">
    <vue3-simple-html2pdf
      ref="vue3SimpleHtml2pdf"
      :options="pdfOptions"
      :filename="exportFilename"
    >
      <div
        class="container for-print pt-3"
        id="content"
        style="position: relative"
      >
        <div
          class="row bg-print-front"
          id="content-player"
          style="min-height: 100vh"
        >
          <div class="col-12">
            <div class="row py-2">
              <div
                class="col-md-12 d-flex align-items-top justify-content-between"
                style="margin-top: 30px"
              >
                <div class="name-side d-flex">
                  <img
                    v-if="team.clubDetails"
                    :src="team.clubDetails?.logo || '/images/club-logo-default-thumb.jpg'"
                    alt="Club Logo"
                    class="me-2 pe-0 text-end"
                    style="width: 70px; height: 70px"
                  />
                  <h3
                    class="pt-1 pb-0 mb-4"
                    style="font-weight: 800"
                  >
                    {{ team.clubDetails?.shortName }} - {{ team.ageGroup }} - {{ team.gender }} <br /><small class="ps-0 ms-0 text-secondary fs-4 fw-1">{{ team.shortName }}</small>
                  </h3>
                </div>
                <div
                  class="league-side d-flex flex-column align-items-end"
                  v-if="getLeagueValue && getLeagueValue.shortName !== 'No League'"
                >
                  <img
                    v-if="getLeagueValue?.logo"
                    :src="getLeagueValue.logo"
                    alt="League Logo"
                    class="me-0 mb-1"
                    style="width: 70px; height: 70px"
                  />
                  <small class="text-muted">{{ league.shortName }}</small>
                </div>
              </div>
            </div>
            <div
              class="mb-4"
              style="margin-top: 10px; margin-bottom: 20px"
            ></div>

            <div
              v-if="players"
              class="row gx-1 gy-1"
            >
              <div
                class="col col-lg-4 card-group"
                v-for="player in sortedPlayers"
                :key="player.id"
                :class="[player?.display == 'Disable' ? 'd-none' : '']"
                aria-current="true"
              >
                <div
                  class="card p-0 item-card"
                  style="overflow: hidden; border: 1px solid #cccccc"
                >
                  <div class="row g-0">
                    <div class="col-md-4 position-relative text-center">
                      <img
                        :src="player.photo ? player.photo : '/images/team-player-profile.jpg'"
                        :alt="player.lastName"
                        class="img-fluid w-100 player-image"
                      /><br />
                      <span
                        class="shirt-number bg-primary"
                        style="display: block; font-size: 22px; font-weight: 700; letter-spacing: -1px; line-height: 1.2; padding: 1px 0; width: 100%; height: 32px; text-align: center; color: #ffffff"
                      >
                        {{ player.shirtNumber }}
                      </span>
                    </div>
                    <div
                      class="col-md-8"
                      style="overflow: hidden; padding: 4px"
                    >
                      <div class="card-body position-relative px-2 pt-0 pb-2 mt-2">
                        <QrcodeVue
                          v-if="player.youtubeHighlightVideo"
                          :value="player.youtubeHighlightVideo"
                          type="svg"
                          level="H"
                          :size="60"
                          class="qrcode"
                          style="width: 60px; height: 60px; position: absolute; right: 2px; top: -6px"
                        />

                        <h6
                          class="player-name card-title smaller-title mt-1 mb-1"
                          style="font-size: 12px; margin-top: 8px; max-width: 116px; overflow-wrap: break-word"
                        >
                          <strong> {{ player.firstName }} {{ player.middleName }} {{ player.lastName }}</strong>
                        </h6>
                        <h6 class="">
                          <span class="py-1 px-2 badge rounded-pill bg-accent player-position mb-0">
                            {{ Array.isArray(player.position) ? player.position.join(", ") : player.position }}
                          </span>
                        </h6>

                        <div class="d-flex flex-column">
                          <div>
                            <small
                              class="my-data me-3"
                              style="color: #000000; font-size: 10px; line-height: 1.3"
                            >
                              <span
                                class="text-muted"
                                style="color: #888888 !important"
                                >Class Of:</span
                              >
                              {{ formatYear(player.classOf) }}</small
                            >
                          </div>

                          <small
                            class="my-data"
                            style="color: #000000; font-size: 10px; line-height: 1.3"
                            ><span
                              class="text-muted"
                              style="color: #888888 !important"
                              >GPA:</span
                            >
                            {{ player.gpa }}</small
                          >
                          <div>
                            <small
                              class="my-data"
                              style="color: #000000; font-size: 10px; line-height: 1.3"
                              >{{ player.phone }}</small
                            >
                            <small
                              class=""
                              style="color: #000000; font-size: 10px; line-height: 1.3"
                            >
                              <span
                                class="text-uppercase fw-bold"
                                style="color: orange; float: right; margin-top: 8px"
                              >
                                {{ player.committed && player.committed == "Enable" ? "committed" : "" }}</span
                              ></small
                            >
                          </div>
                          <small
                            class="my-data"
                            style="color: #000000; font-size: 10px; line-height: 1.3"
                            >{{ player.email }}</small
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="alert alert-info">There are no players in this squad. Add a Player to start</div>
            </div>
          </div>
          <div style="display: block; margin-top: 30px; width: 100%; bottom: 0; border-top: 1px solid #ddd; text-align: center">
            <div
              class=""
              style="text-align: center; color: #666; font-size: 10px; padding: 20px 0"
            >
              Brought to you by <a href="https://teamsheetpro.com">TeamSheetPro.com</a>: Elevate your Game!
            </div>
          </div>
        </div>
        <!-- Break -->
        <div class="html2pdf__page-break my-0 py-0"></div>
        <div
          class="row pt-0 pb-4 mt-0 mb-4"
          id="content-staff"
          style="position: relative; min-height: 100vh"
        >
          <div class="col-12 pb-1">
            <div class="row bg-print-back">
              <div class="col-12">
                <div class="row py-4">
                  <div class="col-md-12 d-flex align-items-top justify-content-between">
                    <div class="name-side d-flex">
                      <img
                        v-if="team.clubDetails"
                        :src="team.clubDetails?.logo || '/images/club-logo-default-thumb.jpg'"
                        alt="Club Logo"
                        class="me-2 pe-0 text-end"
                        style="width: 50px; height: 50px"
                      />
                      <h3
                        class="pt-1 pb-0 mb-0"
                        style="font-weight: 800"
                      >
                        {{ team.clubDetails?.shortName }} - {{ team.ageGroup }} - {{ team.gender }}<br />
                        <small class="ps-0 ms-0 mt-1 pt-0 text-secondary fs-6 fw-1"> {{ team.name }}</small>
                      </h3>
                    </div>
                    <div class="league-side d-flex flex-column align-items-end">
                      <img
                        v-if="league.logo"
                        :src="league.logo"
                        alt="League Logo"
                        class="me-0 mb-1"
                        style="width: 50px; height: 50px"
                      />
                      <small class="text-muted">{{ league.shortName }}</small>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 pt-0 pb-2 border-right">
                    <div class="d-block">
                      <div
                        class="staff-title rounded-3 border"
                        style="font-size: 20px; line-height: 1.4; padding: 12px 0; color: #222222; font-weight: 700; text-align: center"
                      >
                        Events
                      </div>
                    </div>
                    <div
                      v-if="teamEvents && teamEvents.length"
                      class="px-0 event-list-wrapper"
                      style="display: flex; flex-direction: column"
                    >
                      <div
                        class="px-1 event-list"
                        style="background-color: #ffffff; display: flex; flex-direction: column"
                        v-for="teamEvent in sortedTeamEvents"
                        :key="teamEvent.id"
                        aria-current="true"
                      >
                        <div
                          class="g-0 event-list-item"
                          style="display: block; padding-bottom: 4px; background-color: #ffffff"
                        >
                          <div class="px-1">
                            <div
                              class="position-relative pt-2"
                              style="display: block"
                            >
                              <p
                                class="mb-0 pb-0 fw-bold event-name-text"
                                style="font-weight: 700; margin-bottom: 0; color: #000000"
                              >
                                {{ teamEvent.name }}
                              </p>
                              <div class="d-flex justify-content-between pb-1">
                                <div class="py-0">
                                  <small
                                    class="event-date"
                                    style="font-size: 12px; color: #333333; line-height: 1"
                                  >
                                    {{ teamEvent.date }}
                                  </small>
                                </div>
                                <div class="py-0 text-end">
                                  <small
                                    class="event-location"
                                    style="font-size: 12px; color: #555555; line-height: 1"
                                  >
                                    {{ teamEvent.location }}
                                  </small>
                                </div>
                              </div>
                              <div style="display: block; border-bottom: 1px solid #ddd"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-8 px-0">
                    <div class="d-block">
                      <div
                        class="staff-title rounded-3 border"
                        style="font-size: 20px; line-height: 1.4; padding: 12px 0; background-color: #eeeeee; color: #222222; font-weight: 700; text-align: center"
                      >
                        Staff Members
                      </div>
                    </div>
                    <div class="row g-1 py-4">
                      <div
                        class="col col-sm-6 col-md-6 card-group"
                        v-for="member in sortedMembers"
                        :key="member.id"
                        aria-current="true"
                      >
                        <div
                          class="card mb-3 p-0 item-card"
                          style="overflow: hidden; border: 1px solid #cccccc"
                        >
                          <div class="row g-0">
                            <div class="col-md-4 position-relative text-center">
                              <img
                                :src="member.photo ? member.photo : 'images/team-player-profile.jpg'"
                                :alt="member.lastName"
                                :style="{
                                  backgroundSize: 'cover',
                                  // backgroundImage: `url(${member.photo ? member.photo : 'images/team-player-profile.jpg'})`,
                                }"
                                class="img-fluid w-100 player-image"
                              />
                            </div>
                            <div class="col-md-8">
                              <div class="card-body position-relative p-2">
                                <h6 class="member-title smaller-title">
                                  {{ member.firstName }} {{ member.middleName }}
                                  {{ member.lastName }}
                                </h6>
                                <p class="p-2 badge rounded-pill bg-accent">
                                  {{ getMemberTitle(member.titleId) }}
                                </p>
                                <br />
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-12 d-flex flex-column py-2">
                                <small class="my-data">
                                  {{ member.phone }}
                                </small>
                                <small class="my-data">
                                  {{ member.email }}
                                </small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="players"
                      class="row gx-1 py-0"
                    >
                      <div class="col-md-12 py-0 my-0">
                        <div class="row g-0 d-flex justify-content-between py-0 player-row-header">
                          <div class="col-md-1 text-start">
                            <small
                              class="ms-1"
                              style="color: #888888; font-size: 11px"
                              >Number</small
                            >
                          </div>
                          <div class="me-2 col-md-4 text-start">
                            <small style="color: #888888; font-size: 11px"> Name </small>
                          </div>
                          <div class="col-md-3 text-start">
                            <small
                              class="text-start w-30"
                              style="color: #888888; font-size: 11px"
                              >Class Of</small
                            >
                          </div>
                          <div class="col-md-3 p-0 pb-1 text-end">
                            <small class="pe-2">
                              <span
                                class=""
                                style="color: #888888; font-size: 11px"
                              >
                                Position
                              </span>
                            </small>
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-md-12 py-0 my-0 player-list-wrapper"
                        v-for="player in sortedPlayers"
                        :key="player.id"
                        aria-current="true"
                        :class="[player?.display == 'Disable' ? 'd-none' : '']"
                      >
                        <div
                          class="d-flex flex-column p-0"
                          style=""
                        >
                          <div
                            class="row g-0 d-flex justify-content-between py-0 player-row mb-1"
                            style="background-color: #f8f8f8; margin-bottom: 2px"
                          >
                            <div class="col-sm-1 text-start">
                              <div
                                class="shirt-number2 rounded-circle bg-primary ms-2 mt-1"
                                style="
                                  font-size: 1.1em;
                                  font-family: Arial, Helvetica, sans-serif;
                                  font-weight: 700;
                                  letter-spacing: -1px;
                                  bottom: 0;
                                  left: 0em;
                                  line-height: 1.6;
                                  padding: 1px;

                                  width: 1.7em;
                                  height: 1.7em;
                                  text-align: center;
                                  color: #ffffff;
                                "
                              >
                                {{ player.shirtNumber }}
                              </div>
                            </div>
                            <div class="col-sm-5 text-start">
                              <p class="mt-1 mb-0 pb-0 fw-bold player-full-name">
                                {{ player.firstName }} {{ player.middleName }}
                                {{ player.lastName }}
                              </p>
                            </div>
                            <div class="col-sm-3 text-start d-flex justify-content-between align-items-center">
                              <small class="text-start w-30 player-class-of">{{ formatYear(player.classOf) }}</small>
                              <div>
                                <small
                                  class=""
                                  style="color: #000000; font-size: 10px; line-height: 1.3"
                                >
                                  <span
                                    class="text-uppercase fw-bold"
                                    style="color: orange; float: right"
                                  >
                                    {{ player.committed && player.committed == "Enable" ? "committed" : "" }}</span
                                  ></small
                                >
                              </div>
                            </div>

                            <div class="col-md-3 p-0 pb-1 text-end">
                              <small class="pe-2">
                                <span class="px-2 badge rounded-pill bg-accent player-position">
                                  {{ Array.isArray(player.position) ? player.position.join(", ") : player.position }}
                                </span>
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="display: block; margin-top: 30px; width: 100%; bottom: 0; border-top: 1px solid #ddd; text-align: center">
            <div
              class=""
              style="text-align: center; color: #666; font-size: 10px; padding: 20px 0"
            >
              Brought to you by <a href="https://teamsheetpro.com">TeamSheetPro.com</a>: Elevate your Game!
            </div>
          </div>
        </div>
      </div>
    </vue3-simple-html2pdf>
    <button
      class="btn btn-outline-primary btn-sm pdf-button"
      @click="download"
    >
      <img
        src="../assets/icon/tube_spinner.svg"
        width="20"
        height="20"
        v-show="isPdfDownloading"
      />
      Download pdf
    </button>
  </div>
</template>
<script>
import Vue3Html2pdf from "vue3-html2pdf";
import { jsPDF } from "jspdf";
import { nextTick } from "vue";
import { ref, onMounted, computed } from "vue";
import { db } from "../firebase";
import { useRoute } from "vue-router";
import QrcodeVue from "qrcode.vue";
import { collection, getDocs, query, where, doc, getDoc } from "firebase/firestore";

export default {
  components: {
    Vue3Html2pdf,
    QrcodeVue,
  },
  props: ["league"],
  data() {
    return {
      isPdfDownloading: false,
      pdfOptions: {
        margin: 1,
        image: {
          type: "png", // Change to jpeg for better compression
          quality: 1, // Adjust the quality (0.8 is a good balance)
          DisablePdfCompression: 1,
          PrintBackground: 1,
        },
        html2canvas: {
          scale: 5, // Reducing scale can decrease file size
          dpi: 250, // Lower DPI for smaller file size
          letterRendering: true,
          useCORS: true,
        },
        jsPDF: {
          unit: "mm",
          format: "a4",
          orientation: "p",
        },
      },
      exportFilename: "my-team-sheet.pdf",
    };
  },
  methods: {
    download() {
      this.newPdfGenerator();
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
    setPdfFalse() {
      this.isPdfDownloading = false;
    },

    //JS PDF OPTIONS
    newPdfGenerator() {
      this.isPdfDownloading = true;
      const jsPdfOptions = {
        autoPaging: "text",
        margin: [4, 1, 0, 1],
        width: 205,
        windowWidth: 977,
        precision: 2,
      };
      const headerMarginDifferenc = 0;
      const doc = new jsPDF();
      let pageHeight = doc.internal.pageSize.getHeight();
      pageHeight -= headerMarginDifferenc;
      doc.setFont("Arial", "sans-serif");
      doc.html(document.getElementById("content-player"), {
        callback: (doc2) => {
          doc2.html(document.getElementById("content-staff"), {
            callback: (doc3) => {
              doc3.save();
              this.isPdfDownloading = false;
            },
            x: 0,
            y: pageHeight,
            ...jsPdfOptions,
          });
        },
        x: 0,
        y: 0,
        ...jsPdfOptions,
      });
    },
  },

  setup(props) {
    const isLoading = ref(true);
    const activeTab = ref("squad");
    const team = ref({
      clubDetails: {
        logo: "",
        shortName: "",
      },
    });
    const teamEvents = ref([]);
    const club = ref({});
    const league = ref({});
    const players = ref([]);
    const members = ref([]);
    const titles = ref([]);

    const route = useRoute();

    const sortedPlayers = computed(() => {
      return players.value.sort((a, b) => a.shirtNumber - b.shirtNumber);
    });
    const getLeagueValue = computed(() => props.league);
    const sortedMembers = computed(() => {
      return members.value.sort((a, b) => {
        if (!a.firstName || !b.firstName) {
          return 0;
        }
        return a.firstName.localeCompare(b.firstName);
      });
    });

    const sortedTeamEvents = computed(() => {
      return teamEvents.value.sort((a, b) => a.date - b.date);
    });
    const getMemberTitle = computed(() => {
      return (memberTitleId) => titles.value[memberTitleId] || "";
    });

    const initialMember = {
      id: null,
      firstName: "",
      lastName: "",
      middleName: "",
      titleId: "",
      photo: "",
    };

    const formatYear = (input) => {
      if (input && typeof input.toDate === "function") {
        return input.toDate().getFullYear();
      } else if (input && typeof input === "string") {
        return input.split("-")[0];
      }
      return "";
    };

    const formatDate = (timestamp) => {
      if (timestamp && typeof timestamp.toDate === "function") {
        const dateObject = timestamp.toDate();
        return `${dateObject.getMonth() + 1}/${dateObject.getDate()}/${dateObject.getFullYear()}`;
      }
      return "";
    };

    const currentPlayer = ref(null);

    const fetchData = async () => {
      try {
        const teamId = route.params.teamId;

        const qPlayers = query(collection(db, "players"), where("teamId", "==", teamId));
        const playersSnapshot = await getDocs(qPlayers);
        const playerData = playersSnapshot.docs.map(async (doc) => {
          const qProfile = query(collection(db, "profiles"), where("email", "==", doc.data().email));
          const profilesSnapshot = await getDocs(qProfile);
          if (profilesSnapshot.size && profilesSnapshot.docs[0].data()?.youtubeHighlightVideo) {
            return {
              id: doc.id,
              ...doc.data(),
              youtubeHighlightVideo: profilesSnapshot.docs[0].data()?.youtubeHighlightVideo,
            };
          }
          return {
            id: doc.id,
            ...doc.data(),
          };
        });
        players.value = await Promise.all(playerData);

        //Fetch team
        const teamDoc = await getDoc(doc(db, "teams", teamId));
        if (teamDoc.exists()) {
          team.value = teamDoc.data();
        } else {
          console.error("No such team exists!");
        }

        // Fetch the club's details using the club's ID from the team
        if (team.value && team.value.club) {
          const clubDoc = await getDoc(doc(db, "clubs", team.value.club));
          if (clubDoc.exists()) {
            team.value.clubDetails = clubDoc.data();
          } else {
            console.error("No such club exists!");
          }
        }

        // Fetch members
        const qMembers = query(collection(db, "members"), where("teamId", "==", teamId));
        const membersSnapshot = await getDocs(qMembers);
        members.value = membersSnapshot.docs.map((doc) => {
          return {
            id: doc.id,
            ...doc.data(),
          };
        });

        // Fetch team events
        const qTeamEvents = query(collection(db, "teamEvents"), where("teamId", "==", teamId));
        const teamEventsSnapshot = await getDocs(qTeamEvents);
        teamEvents.value = teamEventsSnapshot.docs.map((doc) => {
          return {
            id: doc.id,
            ...doc.data(),
          };
        });

        // Fetch titles
        const titlesSnapshot = await getDocs(collection(db, "titles"));
        titles.value = titlesSnapshot.docs.reduce((acc, doc) => {
          acc[doc.id] = doc.data().name;
          return acc;
        }, {});

        // Fetch the league's details using the club's leagueId
        if (team.value.clubDetails && team.value.clubDetails.leagueId) {
          const leagueDoc = await getDoc(doc(db, "leagues", team.value.clubDetails.leagueId));
          if (leagueDoc.exists()) {
            league.value = leagueDoc.data();
          } else {
            console.error("No such league exists!");
          }
        }

        const sortedTeamEvents = computed(() => {
          return teamEvents.value.sort((a, b) => a.date - b.date);
        });

        isLoading.value = false;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    onMounted(async () => {
      await fetchData();
    });

    return {
      activeTab,
      league,
      club,
      team,
      players,
      members,
      titles,
      isLoading,
      sortedPlayers,
      sortedMembers,
      sortedTeamEvents,
      formatYear,
      formatDate,
      currentPlayer,
      getMemberTitle,
      teamEvents,
      getLeagueValue,
    };
  },
};
</script>

<!-- STYLES -->

<style scoped lang="scss">
.pdf-button {
  position: absolute;
  top: 0;
  right: 0;
}

.for-print {
  .item-card {
    overflow: hidden;
    border: 1px solid #dddddd;

    .text-muted {
      color: #777777;
    }
  }

  .bg-print-front {
    background-color: rgb(255, 255, 255);
    background-size: cover;
  }

  .bg-print-back {
    background-color: rgb(255, 255, 255);
    background-size: cover;
  }

  .player-list-wrapper {
    &:nth-child(even) {
      background-color: #f8f9fa;
    }

    &:nth-child(odd) {
      background-color: #dce7f3;
    }

    .player-full-name,
    .player-class-of {
      line-height: 1.6;
    }
  }

  .player-row-header {
    border-bottom: 1px solid #ddd;

    * {
      font-size: 10px;
      color: #888;
    }
  }

  .player-row {
    border-bottom: 1px solid #ddd;
  }

  max-width: 900px;

  a {
    text-decoration: none;
    cursor: pointer;
  }

  .event-list-wrapper {
    .event-list {
      &:nth-child(even) {
        background-color: #ffffff;
      }

      &:nth-child(odd) {
        background-color: #efefef;
      }
    }
  }

  .event-list {
    &:last-child {
      padding-bottom: 8px;
      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px;
    }
  }

  .event-list-item {
    /*border-bottom: 1px solid #477c9e;*/
  }

  .event-name-text {
    line-height: 1.2;
    font-size: 11px;
  }

  .event-title-wrapper {
    background-color: #f8f8f8;
  }

  .staff-title-wrapper {
    background-color: #f8f8f8;
    border-radius: 6px;
  }

  .event-name {
    padding-bottom: 0;
    margin-bottom: 0;
    line-height: 1;
  }

  .event-date,
  .event-location {
    font-size: 10px;
    color: #555555;
    line-height: 1;
  }

  .member-title {
    font-weight: 700;
    font-size: 11px;
  }

  .player-name.card-title {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    font-weight: 900;
    letter-spacing: -0.2px;
  }

  .my-data {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 10px;
    font-weight: 400;
    margin: 1px 0;
    line-height: 1.3;
    letter-spacing: -0.2px;
    padding: 0;
  }

  .shirt-number {
    // font-size: 20px;
    // font-family: Arial, Helvetica, sans-serif;
    // font-weight: 700;
    // letter-spacing: -1.5px;
    // left: 0em;
    // line-height: 1.2;
    // padding: 0;
    // border-top-left-radius: 0;
    // border-bottom-left-radius: 13px;
    // border-bottom-right-radius: 0;
    // border-top-right-radius: 0;
    // text-align: center;
    // color: #ffffff;
  }

  .qrcode {
    width: 50px;
    height: 50px;
    position: absolute;
    right: 2px;
    top: -6px;
  }

  .shirt-number2 {
    font-size: 0.8em;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 700;
    letter-spacing: -1px;
    right: 0em;
    line-height: 1.3;
    padding: 1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 13px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    width: 1.5em;
    height: 1.5em;
    text-align: center;
    color: #ffffff;
  }

  .player-position {
    line-height: 1;
  }
}

.loading-spinner {
  /* Your loading spinner styles */
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
