<template>
  <div
    v-if="isLoading"
    class="loading-spinner"
  ></div>
  <div
    v-else
    class="container px-0"
  >
    <div class="row pb-4">
      <div class="col-md-12 d-flex justify-content-between align-items-center">
        <div>
          <h3>Players</h3>
        </div>
        <div>
          <button
            v-if="canCreate || isUserIdMatchWithTeamId"
            @click="copyToClipboard"
            class="btn share-btn"
          >
            <img
              src="../assets/icon/paperIcon.svg"
              width="15"
            />
            <span class="d-none ms-2 d-sm-inline-block"> Share</span>
          </button>

          <button
            v-if="canCreate || isUserIdMatchWithTeamId"
            @click="handleAddPlayer"
            class="btn btn-primary"
          >
            <font-awesome-icon
              :icon="['fas', 'plus']"
              class="icon-color d-inline-block"
            /><span class="ps-2 d-none d-sm-inline-block">Add Player</span>
          </button>

          <button
            v-if="canAdd && isUserNotInThisTeam && !isUserIdMatchWithTeamId"
            @click="() => (showAddMEAsAPlayer = true)"
            class="btn btn-primary ms-2"
          >
            <font-awesome-icon
              :icon="['fas', 'plus']"
              class="icon-color d-inline-block"
            /><span class="ps-2 d-none d-sm-inline-block">Add Me as a Player</span>
          </button>
        </div>
      </div>
    </div>
    <div
      v-if="players"
      class="row"
    >
      <div
        class="col-xs-1 col-sm-6 col-md-6 col-lg-4 card-group"
        v-for="player in sortedPlayers"
        :key="player.id"
        aria-current="true"
      >
        <!-- v-if="player?.display?.toLowerCase() !== 'disable' || canShowHidden" -->
        <div
          :class="player?.display?.toLowerCase() === 'disable' ? 'hidden-player card mb-3 p-0 item-card' : 'card mb-3 p-0 item-card'"
          style=""
        >
          <div class="row g-0">
            <div class="col-md-4 position-relative text-center">
              <img
                :src="player.photo ? player.photo : '/images/team-player-profile.jpg'"
                :alt="player.lastName"
                :style="{
                  backgroundSize: 'cover',
                  backgroundImage: `url(${player.photo ? player.photo : '/images/team-player-profile.jpg'})`,
                }"
                class="img-fluid w-100 player-image"
              />
              <h3 class="mb-0 card-shirt-number py-2 px-3 text-white bg-primary">
                <span class="shirt-digit">
                  {{ player.shirtNumber }}
                </span>
              </h3>
            </div>
            <div class="col-md-8">
              <div class="card-body position-relative ms-1 mt-1">
                <h5 class="card-title smaller-title d-flex justify-content-between">
                  <router-link
                    v-if="canViewDetails || isUserIdMatchWithTeamId || userEmail === player.email"
                    :to="`/player-detail/${player.id}`"
                    >{{ player.firstName }} {{ player.middleName }} {{ player.lastName }} {{ userEmail === player.email ? "(You)" : "" }}</router-link
                  >
                  <p v-else>{{ player.firstName }} {{ player.middleName }} {{ player.lastName }} {{ userEmail === player.email ? "(You)" : "" }}</p>

                  <QrcodeVue
                    v-if="player.youtubeHighlightVideo"
                    :value="player.youtubeHighlightVideo"
                    type="svg"
                    level="H"
                    :size="60"
                    class="qrcode"
                  />
                </h5>
                <div class="d-flex">
                  <h5>
                    <span class="py-2 px-3 badge rounded-start rounded-pill bg-accent">
                      {{ Array.isArray(player.position) ? player.position.join(", ") : player.position }}
                    </span>
                  </h5>
                </div>
                <small class="mb-1"><span class="text-muted">Class Of:</span> {{ formatYear(player.classOf) }}</small>

                <br />
                <small class="mb-1"><span class="text-muted">Born in:</span> {{ formatYear(player.dob) }}</small>
                <br />
                <span
                  class="text-uppercase fw-bold"
                  style="color: orange"
                >
                  {{ player.committed && player.committed == "Enable" ? "Committed" : "" }}</span
                >
              </div>
            </div>
            <div
              class="card-footer d-flex"
              v-if="canViewDetails || canEdit || canDelete || isUserIdMatchWithTeamId || userEmail === player.email || isLoggedUser"
            >
              <button
                v-if="getUserRoleComputed == 'recruiter' || isUserIdMatchWithTeamId || userEmail === player.email"
                class="btn btn-link btn-sm ms-1"
                @click.prevent="handleHeartIconCLick(player)"
              >
                <img
                  :src="player?.playerFollowingByRecruiter?.find((element) => element.userId == userId) ? 'images/fill-heart.svg' : 'images/heart.svg'"
                  style="width: 20px"
                />
                ({{ player.playerFollowingByRecruiter ? player.playerFollowingByRecruiter.length : 0 }})
              </button>
              <div class="col text-end">
                <small class="text-muted">
                  <router-link
                    v-if="canViewDetails || isUserIdMatchWithTeamId || userEmail === player.email || isLoggedUser"
                    :to="`/player-detail/${player.id}`"
                    class="btn btn-sm btn-link p-0"
                  >
                    Player Detail
                  </router-link>
                  <button
                    v-if="canEdit || isUserIdMatchWithTeamId || userEmail === player.email"
                    class="btn btn-link btn-sm ms-1"
                    @click.prevent="editCurrentPlayer(player)"
                  >
                    Edit
                  </button>

                  <button
                    v-if="canDelete || isUserIdMatchWithTeamId"
                    @click="deletePlayer(player.id)"
                    class="btn btn-link btn-sm ms-1"
                  >
                    Delete
                  </button>
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!allPlayers.length">
      <div class="alert alert-info">There are no players in this squad. Add a Player to start</div>
    </div>
    <div v-if="!players.length && searchQuery && allPlayers.length">
      <div class="alert alert-info">No player found for your search.</div>
    </div>
  </div>

  <!-- Modal for Adding Player -->
  <ModalComponent
    v-model="showAddPlayerModal"
    header="Add Player to Team"
  >
    <PlayerFormComponent
      :teamId="teamId"
      :addNewPlayer="true"
      @playerAdded="onPlayerAdded"
      @closeModal="handleCloseAddPlayerModal"
    />
  </ModalComponent>

  <!-- Edit Player Modal -->
  <ModalComponent
    v-model="showEditPlayerModal"
    header="Edit Player"
  >
    <PlayerFormComponent
      :addNewPlayer="false"
      :initialPlayer="currentPlayer"
      :teamDetail="team"
      @playerSaved="onPlayerSaved"
      @closeModal="handleCloseEditPlayerModal"
    />
  </ModalComponent>

  <!-- Add Me as player -->
  <ModalComponent
    v-model="showAddMEAsAPlayer"
    :header="isPlayerDetailAvaliable ? 'Add Player' : 'Add Me as a Player'"
  >
    <AddMeAsAPlayerComponent
      :initialPlayer="currentPlayer"
      :isPlayerDetailAvaliable="isPlayerDetailAvaliable"
      :teamDetail="team"
      @playerSaved="onAddMeAsAPlayer"
      @closeModal="handleCloseAddMeAsPlayerModel"
    />
  </ModalComponent>

  <!-- Confirmation Modal for Deleting Player -->
  <ModalComponent
    v-model="showDeletePlayerModal"
    header="Delete Player"
  >
    <div>
      Are you sure you want to delete this player?
      <div class="mt-3 d-flex justify-content-end">
        <button
          @click="confirmDeletePlayer"
          class="btn btn-danger me-2"
        >
          Yes
        </button>
        <button
          @click="cancelDelete"
          class="btn btn-secondary"
        >
          No
        </button>
      </div>
    </div>
  </ModalComponent>

  <!-- Confirmation Modal for like List currentClickedPlayerData?.playerFollowingByRecruiter ? currentClickedPlayerData?.playerFollowingByRecruiter :-->
  <ModalComponent
    v-model="showRecuriterListModal"
    :header="`Likes Report (${currentClickedPlayerData?.playerFollowingByRecruiter ? currentClickedPlayerData.playerFollowingByRecruiter.length : 0})`"
    :closeable="true"
  >
    <PlayerLikes :recruiterArray="currentClickedPlayerData?.playerFollowingByRecruiter ? currentClickedPlayerData?.playerFollowingByRecruiter : []"></PlayerLikes>
  </ModalComponent>
</template>

<script>
import { nextTick } from "vue";
import { ref, onMounted, computed, watch } from "vue";
import { auth, db } from "../firebase";
import { useRoute, useRouter } from "vue-router";
import { collection, getDocs, query, where, doc, getDoc, deleteDoc, setDoc, updateDoc } from "firebase/firestore";
import PlayerFormComponent from "./PlayerFormComponent.vue";
import AddMeAsAPlayerComponent from "./AddMeAsAPlayerComponent.vue";
import PlayerLikes from "@/components/PlayerLikes.vue";
import ModalComponent from "./ModalComponent.vue";
import { useToast } from "vue-toastification";
import { onAuthStateChanged } from "firebase/auth";
import { getUserRole } from "../utils/auth";
import ROLES from "../utils/roles.json";
import { generateFourDigitNumber } from "@/components/composable/helper.js";
import QrcodeVue from "qrcode.vue";

export default {
  emits: ["updateView"],
  components: {
    PlayerFormComponent,
    AddMeAsAPlayerComponent,
    ModalComponent,
    QrcodeVue,
    PlayerLikes,
  },
  props: {
    searchQuery: {
      type: String,
      default: "",
    },
    gender: {
      type: String,
    },
    clubName: {
      type: String,
    },
    leagueName: {
      type: String,
    },
    age: {
      type: String,
    },
  },

  setup(props, { emit }) {
    const isLoading = ref(true);
    const activeTab = ref("squad");

    const team = ref({
      clubDetails: {
        logo: "",
        shortName: "",
      },
    });

    const valueQR = "https://teamsheetpro.com";
    const sizeQR = ref(60);

    const router = useRouter();
    const club = ref({});
    const league = ref({});
    const players = ref([]);
    const allPlayers = ref([]);
    const isUserNotInThisTeam = ref(false);
    const isPlayerDetailAvaliable = ref(false);
    const userRole = ref("");
    const canCreate = ref(false);
    const canAdd = ref(false);
    const canEdit = ref(false);
    const canDelete = ref(false);
    const canViewDetails = ref(false);
    const canShowHidden = ref("");
    const isLoggedUser = ref(false);
    const userEmail = auth.currentUser?.email;
    const userId = auth.currentUser?.uid;
    const recruiterFollowingPlayer = ref([]);
    const searchQuery = ref(router.currentRoute.value.query.search || "");
    const currentClickedPlayerData = ref("");
    const ListSquad = {
      emits: ["updateView"],
    };
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        userRole.value = await getUserRole(user);
        canCreate.value = ROLES.PLAYER.CREATE.includes(userRole.value?.toLowerCase());
        canAdd.value = ROLES.TEAM.ADD.includes(userRole.value?.toLowerCase());
        canEdit.value = ROLES.PLAYER.EDIT.includes(userRole.value?.toLowerCase());
        canDelete.value = ROLES.PLAYER.DELETE.includes(userRole.value?.toLowerCase());
        canViewDetails.value = ROLES.PLAYER.VIEWDETAILS.includes(userRole.value?.toLowerCase());
        canShowHidden.value = ROLES.PLAYER.SHOWHIDDEN.includes(userRole.value?.toLowerCase());
      }
    });

    const route = useRoute();
    const toast = useToast();
    const sortedPlayers = computed(() => {
      return players.value.sort((a, b) => a.shirtNumber - b.shirtNumber);
    });

    const getUserRoleComputed = computed(() => userRole.value);
    const isUserIdMatchWithTeamId = computed(() => {
      return Array.isArray(team.value.ownerId) ? team.value.ownerId.includes(userId) : userId === team.value.ownerId;
    });
    function searchPlayer() {
      players.value = allPlayers.value.filter((player) => {
        return player?.firstName?.toLowerCase().includes(searchQuery?.value?.toLowerCase()) || player?.lastName?.toLowerCase().includes(searchQuery?.value?.toLowerCase()) || player?.position?.includes(searchQuery?.value);
      });
      // change url
      // router.push({ query: { search: searchQuery.value } });
    }

    const formatYear = (input) => {
      if (input && typeof input.toDate === "function") {
        return input.toDate().getFullYear();
      } else if (input && typeof input === "string") {
        return input.split("-")[0];
      }
      return "";
    };

    const currentPlayer = ref(null);

    function editCurrentPlayer(player) {
      currentPlayer.value = player; // Assign the player to currentPlayer
      showEditPlayerModal.value = true;
    }
    function handleHeartIconCLick(playerData) {
      if (getUserRoleComputed.value == "recruiter") {
        followCurrentPlayer(playerData);
      } else {
        currentClickedPlayerData.value = playerData;
        showRecuriterListModal.value = true;
      }
    }
    async function followCurrentPlayer(player) {
      const userRef = doc(db, "profiles", userId);
      const userProfile = await getDoc(userRef);
      if (!userProfile.data().hasOwnProperty("followingPlayers") && player.id) {
        await updateDoc(doc(db, "profiles", userId), {
          followingPlayers: [player.id],
        });
      } else {
        let index = recruiterFollowingPlayer.value.indexOf(player.id);
        index !== -1 ? recruiterFollowingPlayer.value.splice(index, 1) : recruiterFollowingPlayer.value.push(player.id);
        await updateDoc(doc(db, "profiles", userId), {
          followingPlayers: [...recruiterFollowingPlayer.value],
        });
      }
      addRecruiter(player);
    }
    async function addRecruiter(player) {
      const userRef = query(collection(db, "profiles"), where("email", "==", player.email)); //doc(db, "profiles", player.id);
      const userProfile = await getDocs(userRef);
      let loadObjectData = "";
      let loadObjectDataId = "";
      if (userProfile.docs[0]?.data()) {
        loadObjectData = userProfile.docs[0].data();
        loadObjectDataId = userProfile.docs[0].id;
        let _obj = { userId: userId, likedDate: new Date() };
        if (!loadObjectData.hasOwnProperty("playerFollowingByRecruiter") && userId) {
          await updateDoc(doc(db, "profiles", loadObjectDataId), {
            playerFollowingByRecruiter: [_obj],
          });
          player.playerFollowingByRecruiter = [_obj];
        } else {
          let playerFollowingByRecruiterArray = loadObjectData.playerFollowingByRecruiter;
          let index = playerFollowingByRecruiterArray.findIndex((cur) => cur.userId == userId);
          let _obj = { userId: userId, likedDate: new Date() };
          index !== -1 ? playerFollowingByRecruiterArray.splice(index, 1) : playerFollowingByRecruiterArray.push(_obj);

          await updateDoc(doc(db, "profiles", loadObjectDataId), {
            playerFollowingByRecruiter: [...playerFollowingByRecruiterArray],
          });

          player.playerFollowingByRecruiter = [...playerFollowingByRecruiterArray];
        }
      }
    }
    async function getRecruiterFollowingPlayer() {
      const userRef = doc(db, "profiles", userId);
      const userProfile = await getDoc(userRef);
      if (userProfile.data().hasOwnProperty("followingPlayers")) {
        recruiterFollowingPlayer.value = [...userProfile.data().followingPlayers];
      }
    }
    async function copyToClipboard() {
      const urlArray = [];
      if (route.params.teamId) {
        const teamDoc = doc(db, "teams", route.params.teamId);
        const fourDigitNUmber = generateFourDigitNumber(route.params.teamId);
        // Updating the document with the new field data using merge option
        //mlsnext_syc_boys_07_0001
        let dynamicUrl = "https://teamsheetpro.com/#/";
        if (props.leagueName) urlArray.push(props.leagueName.replaceAll(" ", "_"));
        if (props.clubName) urlArray.push(props.clubName);
        if (props.gender) urlArray.push(props.gender);
        if (props.age) urlArray.push(props.age);
        if (fourDigitNUmber) urlArray.push(fourDigitNUmber);
        urlArray.forEach((cur, index) => {
          if (index == 0) {
            dynamicUrl = dynamicUrl + "" + cur + "";
          } else {
            dynamicUrl = dynamicUrl + "_" + cur + "";
          }
        });
        await setDoc(teamDoc, { uniqueFourDigitId: fourDigitNUmber }, { merge: true });

        navigator.clipboard
          .writeText(dynamicUrl)
          .then(() => {
            toast.success("URL successfully copied to clipboard; paste it in your email and send it.");
          })
          .catch((error) => {
            console.error("Error copying text to clipboard:", error);
          });
      }
    }

    const showAddPlayerModal = ref(false);
    const showEditPlayerModal = ref(false);
    const showAddMEAsAPlayer = ref(false);

    function handleAddPlayer() {
      showAddPlayerModal.value = true;
    }

    const onPlayerAdded = () => {
      showAddPlayerModal.value = false;
      fetchData(); // fetch the updated list of players
    };

    function handleCloseAddPlayerModal() {
      showAddPlayerModal.value = false;
    }

    function onPlayerSaved() {
      fetchData(); // Fetch the updated list of players and other related data
      showEditPlayerModal.value = false;
    }
    function onAddMeAsAPlayer() {
      fetchData(); // Fetch the updated list of players and other related data
      showAddMEAsAPlayer.value = false;
    }

    // handleCloseModelAddMeAsPlayer Close the edit modal without saving handleCloseModelAddMeAsPlayer
    function handleCloseEditPlayerModal() {
      showEditPlayerModal.value = false;
    }
    function handleCloseAddMeAsPlayerModel() {
      showAddMEAsAPlayer.value = false;
    }
    const showDeletePlayerModal = ref(false);
    const playerToDelete = ref(null);
    const showRecuriterListModal = ref(false);

    const deletePlayer = (playerId) => {
      playerToDelete.value = playerId;
      showDeletePlayerModal.value = true;
    };

    const confirmDeletePlayer = async () => {
      if (playerToDelete.value) {
        // Remove player from the database
        await deleteDoc(doc(db, "players", playerToDelete.value));
        toast.success("Player was successfully removed.");
        showDeletePlayerModal.value = false;

        await nextTick(); // Wait for any pending DOM updates

        fetchData(); // fetch the updated list of players
        searchPlayer();
      } else {
        console.error("Error: No player to delete.");
        toast.error("No player to delete");
      }
    };

    const cancelDelete = () => {
      playerToDelete.value = null;
      showDeletePlayerModal.value = false;
    };

    const teamId = route.params.teamId;

    const fetchData = async () => {
      try {
        const qPlayers = query(collection(db, "players"), where("teamId", "==", teamId));
        const playersSnapshot = await getDocs(qPlayers);

        // Use Promise.all for parallel async operations
        const playerDataPromises = playersSnapshot.docs.map(async (doc) => {
          const qProfile = query(collection(db, "profiles"), where("email", "==", doc.data().email));
          const profilesSnapshot = await getDocs(qProfile);
          const profileData = profilesSnapshot.docs[0]?.data() || {};
          return {
            id: doc.id,
            ...doc.data(),
            youtubeHighlightVideo: profileData.youtubeHighlightVideo || "",
            playerFollowingByRecruiter: profileData.playerFollowingByRecruiter || [],
          };
        });

        // Resolve all promises
        players.value = await Promise.all(playerDataPromises);

        allPlayers.value = players.value;
        const isLoggedInUser = allPlayers.value.find((el) => el.email === userEmail);
        if (isLoggedInUser) {
          isLoggedUser.value = true;
          emit("updateView", true);
        }
        //Fetch team
        const teamDoc = await getDoc(doc(db, "teams", teamId));
        if (teamDoc.exists()) {
          team.value = teamDoc.data();
        } else {
          console.error("No such team exists!");
        }

        // Fetch the club's details using the club's ID from the team
        if (team.value && team.value.club) {
          const clubDoc = await getDoc(doc(db, "clubs", team.value.club));
          if (clubDoc.exists()) {
            team.value.clubDetails = clubDoc.data();
          } else {
            console.error("No such club exists!");
          }
        }

        isLoading.value = false;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const checkPlayerIsAddedRequest = async () => {
      //isUserIdMatchWithTeamId
      if (route.query?.PlayerId) {
        try {
          const playerInformation = await getDoc(doc(db, "players", route.query?.PlayerId));

          if (playerInformation.exists()) {
            const playerData = playerInformation.data();
            const playersCollection = collection(db, "players");
            const q = query(playersCollection, where("email", "==", playerData.email.toLowerCase()), where("teamId", "==", route.params.teamId));
            const playersSnapshot = await getDocs(q);
            if (playersSnapshot.docs.length > 0) {
              toast.error("Player with this email already added to the team.");
              router.replace({
                path: `/TeamManage/${route.params.teamId}`,
              });
              throw "Player with this email already added to the team.";
            }
            if (!isUserIdMatchWithTeamId.value) {
              toast.error("Only this team owner can accept the request.");
              throw "Only this team owner can accept the request.";
            }
            currentPlayer.value = { ...playerData };
            currentPlayer.value.teamId = route.params.teamId;
            isPlayerDetailAvaliable.value = true;
            showAddMEAsAPlayer.value = true;
          } else {
            toast.error("No player found");
            router.replace({
              path: `/TeamManage/${route.params.teamId}`,
            });
          }
        } catch (error) {
          console.error("Error :", error);
        }
      }
    };
    onMounted(async () => {
      await fetchData(); // Call fetchData when the component mounts
      await getRecruiterFollowingPlayer();
      searchPlayer();
      emit("updateView");
      checkPlayerIsAddedRequest();
    });
    watch(allPlayers, () => {
      isUserNotInThisTeam.value = allPlayers.value.find((el) => el.email === userEmail) ? false : true;
    });
    return {
      onAddMeAsAPlayer,
      isPlayerDetailAvaliable,
      getUserRoleComputed,
      activeTab,
      copyToClipboard,
      league,
      club,
      team,
      players,
      isLoading,
      sortedPlayers,
      formatYear,
      handleAddPlayer,
      onPlayerAdded,
      showAddPlayerModal,
      handleCloseAddPlayerModal,
      handleCloseAddMeAsPlayerModel,
      deletePlayer,
      showDeletePlayerModal,
      showEditPlayerModal,
      confirmDeletePlayer,
      cancelDelete,
      showEditPlayerModal,
      showAddMEAsAPlayer,
      editCurrentPlayer,
      currentPlayer,
      onPlayerSaved,
      handleCloseEditPlayerModal,
      canCreate,
      canEdit,
      allPlayers,
      canDelete,
      canViewDetails,
      userEmail,
      canShowHidden,
      userId,
      searchQuery,
      teamId,
      searchPlayer,
      ListSquad,
      isUserIdMatchWithTeamId,
      isLoggedUser,
      valueQR,
      sizeQR,
      followCurrentPlayer,
      recruiterFollowingPlayer,
      handleHeartIconCLick,
      showRecuriterListModal,
      currentClickedPlayerData,
      AddMeAsAPlayerComponent,
      canAdd,
      isUserNotInThisTeam,
    };
  },
};
</script>

<!-- STYLES -->

<style scoped lang="scss">
$xs-screen: 575px;

a {
  text-decoration: none;
  cursor: pointer;
}

.qrcode {
  width: 50px;
  height: 50px;
  position: absolute;
  right: 3px;
  bottom: 4px;
}

.shirt-number {
  /**top: 0em;
  font-size: 1.3em;
  font-weight: 700;
  letter-spacing: -1.5px;
  right: 0em;
  line-height: 1.9;
  background-color: #215178;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 33px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  width: 2.4em;
  height: 2.4em;
  text-align: center;
  color: #ffffff;*/
}

.card-shirt-number {
  font-size: 1.2em;
  font-weight: 700;
  @media screen and (max-width: $xs-screen) {
    font-size: 1.8em;
  }
}

.shirt-digit {
  font-size: 1.2em;
}

.player-position {
  background-color: #e5f1f8;
  text-align: center;
  color: #2e5571;
  font-weight: 300;
  text-transform: uppercase;
  font-size: 0.9em;
  padding: 0.3em 0;
}

.item-card {
  &:hover {
    box-shadow: 0 0 12px #999;

    img {
      background-size: 115%;
      background-repeat: no-repeat;
    }
  }

  .svg-inline--fa {
    color: #93bac9;
  }

  .card-img-top {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    transition: all ease-in-out 4ms;
    cursor: pointer;
  }
}

.loading-spinner {
  /* Your loading spinner styles */
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
.hidden-player {
  filter: opacity(0.6);
}
.hidden-player:hover {
  filter: opacity(1);
}
.share-btn {
  color: rgb(33 37 41 / 75%);
  border: 1px rgb(33 37 41 / 75%) solid;
  margin-right: 10px;
}
</style>
