<template>
  <div class="row">
    <div
      class="col table-responsive"
      v-if="recruiterDetail.length"
    >
      <table class="table table-sm table-hover table-striped">
        <thead class="table-header">
          <tr>
            <th
              scope="col-1"
              class="fs-7 py-2 px-3"
            >
              Date Liked
            </th>
            <th
              scope="col-3"
              class="fs-7 py-2 px-3"
            >
              Name
            </th>
            <th
              scope="col-2"
              class="fs-7 py-2 px-3"
            >
              Title
            </th>
            <th
              scope="col-3"
              class="fs-7 py-2 px-3"
            >
              Phone
            </th>
            <th
              scope="col-2"
              class="fs-7 py-2 px-3"
            >
              Email
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="user in recruiterDetail"
            :key="user.id"
          >
            <td
              scope="col-1"
              class="fs-7 py-2 px-3"
            >
              <!-- {{ formatDate(user.createdAt) }} -->
              {{ formatDate(user.date) }}
            </td>
            <td
              scope="col-3"
              class="fs-7 py-2 px-3"
            >
              {{ user.firstName }} {{ user.lastName }}
            </td>
            <td
              scope="col-2"
              class="fs-7 py-2 px-3"
            >
              {{ user.title }}
            </td>
            <td
              scope="col-3"
              class="fs-7 py-2 px-3"
            >
              {{ user.phone }}
            </td>
            <td
              scope="col-2"
              class="fs-7 py-2 p-3"
            >
              {{ user.email }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      v-else
      class="d-flex justify-content-center align-items-center"
    >
      <span>No Data Available</span>
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref, defineProps } from "vue";
import { doc, getDoc } from "firebase/firestore";
import { db, auth } from "../firebase";
const props = defineProps(["recruiterArray"]);
const recruiterDetail = ref([]);
onMounted(() => {
  getPlayerData();
});
const getRecruiterData = async (arrayList) => {
  if (arrayList.length) {
    const userProfile = arrayList.map(async (currentElement) => {
      const userRef = doc(db, "profiles", currentElement.userId);
      return await getDoc(userRef);
    });
    let userData = await Promise.all(userProfile);
    recruiterDetail.value = userData.map((ele) => {
      return {
        ...ele.data(),
        id: ele.id,
      };
    });
    recruiterDetail.value = recruiterDetail.value.map((element) => {
      let _obj = arrayList.find((cur) => cur.userId == element.id);
      if (_obj) {
        return {
          date: _obj.likedDate,
          ...element,
        };
      }
      return element;
    });
  }
};

const formatDate = (timestamp) => {
  if (!timestamp || !timestamp.seconds) {
    return "N/A"; // Return a default value if timestamp is missing or invalid
  }
  const date = new Date(timestamp.seconds * 1000);
  const formattedDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  return formattedDate;
};

const getPlayerData = async () => {
  if (props.recruiterArray.length) {
    getRecruiterData(props.recruiterArray);
  } else {
    const userRef = doc(db, "profiles", auth.currentUser?.uid);
    const userProfile = await getDoc(userRef);
    console.log(userProfile.data(), "xxx");
    if (userProfile.data()?.hasOwnProperty("playerFollowingByRecruiter")) {
      console.log(userProfile.data().playerFollowingByRecruiter, "userProfile.data().followingRecruiter");
      getRecruiterData(userProfile.data().playerFollowingByRecruiter);
    }
  }
};

defineExpose({
  recruiterDetail,
});
</script>
