import { createRouter, createWebHashHistory, useRouter } from "vue-router";
import Home from "../views/Home.vue";
import AboutUs from "../views/AboutUs.vue";
import MyClubs from "../views/MyClubs.vue";
import Leagues from "../views/Leagues.vue";
import MyEvents from "../views/MyEvents.vue";
import PrivacyPolicy from "../views/PrivacyPolicy.vue";
import CropperPage from "../views/CropperPage.vue";
import SupAdminDash from "../views/SupAadminDash.vue";
import AdminDash from "../views/AdminDash.vue";
import SquadComponent from "../components/SquadComponent.vue";
import NotFoundComponent from "../views/NotFoundComponent.vue";
import RequestFrom from "../views/RequestForm.vue";
import AddTeamOwner from "../views/AddTeamOwner.vue";
import ContactUs from "../views/ContactUs.vue";
import MyTeams from "../views/MyTeams.vue";
import { checkAuth } from "../utils/auth.js";
import ROLES from "../utils/roles.json";
import { auth, db } from "../firebase";
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";

const routes = [
  {
    path: "/superadmin-dashboard",
    name: "SupAdminDash",
    component: SupAdminDash,
    children: [
      {
        path: "", // Empty path means it will be the default route
        name: "SupAdminDashDefault",
        redirect: "/superadmin-dashboard/users", // Redirect to the 'users' route
      },
      {
        path: "users",
        name: "Users",
        component: () => import("@/components/UsersList.vue"),
      },
      {
        path: "emailjs",
        name: "Emailjs",
        component: () => import("@/views/EmailJs.vue"),
      },

      {
        path: "leagues",
        name: "Leagues",
        component: () => import("@/views/Leagues.vue"),
      },
      {
        path: "users/:id",
        name: "UserDetail",
        component: () => import("../views/UpdateProfile.vue"),
      },
    ],
    beforeEnter: async (to, from, next) => {
      const user = await checkAuth();
      if (user.status) {
        if (user.role?.toLowerCase() !== "superadmin") {
          next({ name: "Home" }); // redirect to home if user is logged in
          return;
        }
        next(); // proceed to login if user logged in
      } else {
        next({ name: "Login" }); // redirect to Login if user is not logged in
      }
    },
  },
  {
    path: "/admin-dashboard",
    name: "AdminDash",
    component: AdminDash,
    beforeEnter: async (to, from, next) => {
      const user = await checkAuth();
      if (user.status) {
        next(); // proceed to login if user logged in
      } else {
        next({ name: "Login" }); // redirect to Login if user is not logged in
      }
    },
  },
  {
    path: "/subscriber-page",
    name: "Home",
    component: Home,
    beforeEnter: async (to, from, next) => {
      const user = await checkAuth();
      if (user.status) {
        next(); // proceed to login if user logged in
      } else {
        next({ name: "Login" }); // redirect to Login if user is not logged in
      }
    },
  },
  {
    path: "/about-us",
    name: "About",
    component: AboutUs,
    beforeEnter: async (to, from, next) => {
      const user = await checkAuth();
      if (user.status) {
        next(); // proceed to login if user logged in
      } else {
        next({ name: "Login" }); // redirect to Login if user is not logged in
      }
    },
  },
  {
    path: "/privacy-policy",
    name: "Privacy",
    component: PrivacyPolicy,
    beforeEnter: async (to, from, next) => {
      const user = await checkAuth();
      if (user.status) {
        next(); // proceed to login if user logged in
      } else {
        next({ name: "Login" }); // redirect to Login if user is not logged in
      }
    },
  },
  {
    path: "/",
    name: "Main",
    component: Home,
    beforeEnter: async (to, from, next) => {
      const user = await checkAuth();
      if (user.status) {
        switch (user.role?.toLowerCase()) {
          case "subscriber":
            next({ name: "Home" });
            break;
          case "superadmin":
            next({ name: "SupAdminDashDefault" });
            break;
          case "admin":
            next({ name: "AdminDash" });
            break;
          default:
            next({ name: "Home" });
        }
      } else {
        next({ name: "Login" }); // redirect to Login if user is not logged in
      }
    },
  },

  {
    path: "/leagues",
    name: "Leagues",
    component: Leagues,
    beforeEnter: async (to, from, next) => {
      const user = await checkAuth();
      if (user.status) {
        next(); // proceed to login if user logged in
      } else {
        next({ name: "Login" }); // redirect to Login if user is not logged in
      }
    },
  },
  {
    path: "/my-clubs",
    name: "MyClubs",
    component: MyClubs,
    beforeEnter: async (to, from, next) => {
      const user = await checkAuth();
      if (user.status) {
        next(); // proceed to login if user logged in
      } else {
        next({ name: "Login" }); // redirect to Login if user is not logged in
      }
    },
  },
  {
    path: "/request",
    name: "Request",
    component: RequestFrom,
    beforeEnter: async (to, from, next) => {
      const user = await checkAuth();
      if (user.status) {
        next(); // proceed to login if user logged in
      } else {
        next({ name: "Login" }); // redirect to Login if user is not logged in
      }
    },
  },

  {
    path: "/add-team-owner/:teamId",
    name: "AddTeamOwner",
    component: AddTeamOwner,
    props: (route) => ({
      teamId: route.params.teamId,
      gender: route.query.gender,
      leagueName: route.query.leagueName,
      clubName: route.query.clubName,
      age: route.query.age,
    }),
  },

  {
    path: "/contact",
    name: "Contact",
    component: ContactUs,
    beforeEnter: async (to, from, next) => {
      const user = await checkAuth();
      if (user.status) {
        next(); //
      } else {
        next({ name: "Login" }); // redirect to Login if user is not logged in
      }
    },
  },
  {
    path: "/acceptteamowner",
    name: "AcceptTeamOwner",
    component: () => import("@/views/AddTeamAcceptance.vue"),
  },
  {
    path: "/my-teams/:team*",
    name: "MyTeams",
    component: MyTeams,
    props: (route) => ({ searchQuery: route.query.search || "" }),
    beforeEnter: async (to, from, next) => {
      const user = await checkAuth();
      if (user.status) {
        next(); // proceed to login if user logged in
      } else {
        next({ name: "Login" }); // redirect to Login if user is not logged in
      }
    },
  },
  {
    path: "/TeamManage/:teamId",
    name: "TeamManage",
    component: SquadComponent,
    beforeEnter: async (to, from, next) => {
      const user = await checkAuth();
      if (user.status) {
        const userId = auth.currentUser?.uid;

        // check if user have access to edit team
        const teamQuery = query(collection(db, "teams"), !ROLES.TEAM.VIEWALL.includes(user.role?.toLowerCase()) && where("ownerId", "==", userId)); // if admin | superadmin => can View all teams : can't View
        const teamSnapshots = await getDocs(teamQuery);
        const userTeam = teamSnapshots.docs.filter((teamSnapshot) => to.params.teamId === teamSnapshot.id);
        if (userTeam.length === 0 || !ROLES.TEAM.VIEWDETAILS.includes(user.role?.toLowerCase())) {
          next({ name: "MyTeams" }); // redirect to home if user have permission to create team
          return;
        }
        next(); // proceed to login if user logged in
      } else {
        next({ name: "Login", query: { redirect: to.fullPath } }); // redirect to Login if user is not logged in
      }
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login.vue"),
    beforeEnter: async (to, from, next) => {
      const user = await checkAuth();
      if (user.status) {
        next({ name: "Home" }); // redirect to home if user is logged in
      } else {
        next(); // proceed to login if user is not logged in
      }
    },
  },

  {
    path: "/add-league",
    name: "AddLeague",
    component: () => import("../views/AddLeague.vue"),
    beforeEnter: async (to, from, next) => {
      const user = await checkAuth();
      if (user.status) {
        next(); // proceed to login if user logged in
      } else {
        next({ name: "Login" }); // redirect to Login if user is not logged in
      }
    },
  },
  {
    path: "/edit-league/:id",
    name: "EditLeague",
    component: () => import("../views/EditLeague.vue"),
    beforeEnter: async (to, from, next) => {
      const user = await checkAuth();
      if (user.status) {
        next(); // proceed to login if user logged in
      } else {
        next({ name: "Login" }); // redirect to Login if user is not logged in
      }
    },
  },
  {
    path: "/add-club",
    name: "AddClub",
    component: () => import("../views/AddClub.vue"),
    beforeEnter: async (to, from, next) => {
      const user = await checkAuth();
      if (user.status) {
        next(); // proceed to login if user logged in
      } else {
        next({ name: "Login" }); // redirect to Login if user is not logged in
      }
    },
  },

  {
    path: "/my-events",
    name: "MyEvents",
    component: MyEvents,
    beforeEnter: async (to, from, next) => {
      const user = await checkAuth();
      if (user.status) {
        next(); // proceed to login if user logged in
      } else {
        next({ name: "Login" }); // redirect to Login if user is not logged in
      }
    },
  },
  {
    path: "/edit-club/:clubId",
    name: "EditClub",
    component: () => import("../views/EditClub.vue"),
    beforeEnter: async (to, from, next) => {
      const user = await checkAuth();
      if (user.status) {
        next(); // proceed to login if user logged in
      } else {
        next({ name: "Login" }); // redirect to Login if user is not logged in
      }
    },
  },
  {
    path: "/edit-team/:teamId",
    name: "EditTeam",
    component: () => import("../views/EditTeam.vue"),
    beforeEnter: async (to, from, next) => {
      const user = await checkAuth();

      if (user.status) {
        const userId = auth.currentUser?.uid;
        const canUpdate = ROLES.TEAM.EDIT.includes(user.role?.toLowerCase());
        // check if user have access to edit team
        let arrayQuery;
        let teamQuery;
        let arrayQuerySnapshot;
        let stringQuerySnapshot;
        let mergedQuerySnapshot;
        teamQuery = query(collection(db, "teams"), !ROLES.TEAM.EDIT.includes(user.role?.toLowerCase()) && where("ownerId", "==", userId)); // if admin | superadmin => can View all teams : can't View
        arrayQuery = !ROLES.TEAM.EDIT.includes(user.role?.toLowerCase()) ? query(collection(db, "teams"), where("ownerId", "array-contains", userId)) : "";
        if (arrayQuery != "") {
          stringQuerySnapshot = await getDocs(teamQuery);
          arrayQuerySnapshot = await getDocs(arrayQuery);
          mergedQuerySnapshot = [...stringQuerySnapshot.docs, ...arrayQuerySnapshot.docs];
        } else {
          stringQuerySnapshot = await getDocs(teamQuery);
          mergedQuerySnapshot = [...stringQuerySnapshot.docs];
        }

        const userTeam = mergedQuerySnapshot.filter((teamSnapshot) => to.params.teamId === teamSnapshot.id);
        if (!canUpdate && userTeam.length === 0) {
          next({ name: "MyTeams" }); // redirect to home if user have permission to create team
          return;
        }
        next(); // proceed to login if user logged in
      } else {
        next({ name: "Login" }); // redirect to Login if user is not logged in
      }
    },
  },

  {
    path: "/TeamRows",
    name: "TeamRows",
    component: () => import("../views/TeamRows.vue"),
    beforeEnter: async (to, from, next) => {
      const user = await checkAuth();
      if (user.status) {
        next(); // proceed to login if user logged in
      } else {
        next({ name: "Login" }); // redirect to Login if user is not logged in
      }
    },
  },
  {
    path: "/add-team",
    name: "AddTeam",
    component: () => import("../views/AddTeam.vue"),
    beforeEnter: async (to, from, next) => {
      const user = await checkAuth();

      const canCreate = ROLES.TEAM.CREATE.includes(user.role?.toLowerCase());

      if (user.status) {
        if (!canCreate) {
          next({ name: "MyTeams" }); // redirect to home if user have permission to create team
          return;
        }
        next(); // proceed to login if user logged in
      } else {
        next({ name: "Login" }); // redirect to Login if user is not logged in
      }
    },
  },
  {
    path: "/player-detail/:id",
    name: "PlayerDetail",
    component: () => import("../views/PlayerDetail.vue"),
    beforeEnter: async (to, from, next) => {
      const user = await checkAuth();
      if (user.status) {
        // const userId = auth.currentUser?.uid;
        // // check if user have access to edit team
        // console.log(ROLES.PLAYER.VIEWALLDETAILS.includes(user.role?.toLowerCase()), "here");
        // const playerQuery = query(collection(db, "players"), !ROLES.PLAYER.VIEWALLDETAILS.includes(user.role?.toLowerCase()) && user.role?.toLowerCase() !== "player" && where("addedBy", "==", userId)); // if admin | superadmin => can View all teams : can't View
        // const playerSnapshots = await getDocs(playerQuery);
        // const userPlayer = playerSnapshots.docs.filter((playerSnapshot) => (user.role?.toLowerCase() !== "player" ? to.params.id === playerSnapshot.id : to.params.id === playerSnapshot.id && auth.currentUser.email === playerSnapshot.data().email));
        // if (userPlayer.length === 0) {
        //   next({ name: "MyTeams" }); // redirect to home if user have permission to create team
        //   return;
        // }
        next();
      } else {
        next({ name: "Login" }); // redirect to Login if user is not logged in
      }
    },
  },
  {
    path: "/member-detail/:id",
    name: "MemberDetail",
    component: () => import("../components/staffMemberDetail.vue"),
    beforeEnter: async (to, from, next) => {
      const user = await checkAuth();
      if (user.status) {
        next();
      } else {
        next({ name: "Login" }); // redirect to Login if user is not logged in
      }
    },
  },
  {
    path: "/update-Profile",
    name: "UpdateProfile",
    component: () => import("../views/UpdateProfile.vue"),
    beforeEnter: async (to, from, next) => {
      const user = await checkAuth();
      if (user.status) {
        next(); // proceed to login if user logged in
      } else {
        next({ name: "Login" }); // redirect to Login if user is not logged in
      }
    },
  },
  {
    path: "/cropper-page",
    name: "CropperPage",
    component: CropperPage,
    beforeEnter: async (to, from, next) => {
      const user = await checkAuth();
      if (user.status) {
        next(); // proceed to login if user logged in
      } else {
        next({ name: "Login" }); // redirect to Login if user is not logged in
      }
    },
  },

  {
    path: "/register",
    name: "Register",
    component: () => import("../views/Register.vue"),
    beforeEnter: async (to, from, next) => {
      const user = await checkAuth();
      if (user.status) {
        next({ name: "Home" }); // redirect to home if user is logged in
      } else {
        next(); // proceed to login if user is not logged in
      }
    },
  },
  {
    path: "/password_reset",
    name: "ForgotPassword",
    component: () => import("../views/ForgotPassword.vue"),
    beforeEnter: async (to, from, next) => {
      const user = await checkAuth();
      if (user.status) {
        next({ name: "Home" }); // redirect to home if user is logged in
      } else {
        next(); // proceed to login if user is not logged in
      }
    },
  },

  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    beforeEnter: async (to, from, next) => {
      let fullUrl = window.location.href;
      if (fullUrl.includes("_")) {
        let splitedUrl = fullUrl.split("_");
        let fourDigitId = splitedUrl[splitedUrl.length - 1];
        if (fourDigitId && Number(fourDigitId)) {
          let q = query(collection(db, "teams"), where("uniqueFourDigitId", "==", fourDigitId));
          let teamSnapshot = await getDocs(q);
          if (teamSnapshot.size) {
            return router.push({ name: "TeamManage", params: { teamId: teamSnapshot.docs[0].id } });
          }
        }
      }

      next();
    },
    component: NotFoundComponent,
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

export default router;
