<template>
  <div
    v-if="isLoading"
    class="loading-spinner"
  ></div>
  <div
    v-else
    class="container px-0"
  >
    <div class="row pb-4">
      <div class="col-md-12 d-flex justify-content-between">
        <h3>Team Events</h3>
        <button
          v-if="canCreate || isUserTeamOwner"
          @click="handleAddEvent"
          class="btn btn-primary"
        >
          + Add Event
        </button>
      </div>
    </div>
    <div
      v-if="teamEvents && teamEvents.length"
      class="row row-cols-1 row-cols-sm-2 row-cols-md-4 row-cols-lg-3 g-2"
    >
      <div
        class="col col-sm-6 col-md-4 card-group"
        v-for="teamEvent in sortedTeamEvents"
        :key="teamEvent.id"
        aria-current="true"
      >
        <div
          class="card mb-3 p-0 item-card"
          style=""
        >
          <div class="row g-0">
            <div class="col-md-12">
              <div class="card-body position-relative ms-2 mt-1">
                <h5 class="card-title smaller-title">
                  {{ teamEvent.name }}
                </h5>
                <h4>
                  <span class="badge bg-accent rounded-pill py-0 my-0">
                    {{ teamEvent.date }}
                  </span>
                </h4>

                <p class="text-secondary py-0 my-0">{{ teamEvent.location }}</p>
              </div>
            </div>
          </div>
          <div
            class="card-footer d-flex"
            v-if="canEdit || canDelete || isUserTeamOwner"
          >
            <div class="col text-end">
              <small class="text-muted">
                <button
                  v-if="canEdit || isUserTeamOwner"
                  class="btn btn-link btn-sm ms-1"
                  @click.prevent="editTeamEvent(teamEvent)"
                >
                  Edit
                </button>

                <button
                  v-if="canDelete || isUserTeamOwner"
                  @click="deleteTeamEvent(teamEvent.id)"
                  class="btn btn-link btn-sm ms-1"
                >
                  Delete
                </button>
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="alert alert-info">
        <p>There are no events scheduled for this squad. Add a an event to start</p>
      </div>
    </div>
  </div>

  <!-- Add Event Modal -->
  <ModalComponent
    v-model="showAddEventModal"
    header="Add an Event to Team"
  >
    <EventFormComponent
      :teamId="teamId"
      @eventAdded="onEventAdded"
      @eventSaved="refreshEventList"
      @closeModal="handleCloseAddEventModal"
    />
  </ModalComponent>

  <!-- Edit Event Modal -->
  <ModalComponent
    v-model="showEditEventModal"
    header="Edit Event"
  >
    <EventFormComponent
      :initialEvent="currentEvent"
      @eventSaved="onEventSaved"
      @closeModal="handleCloseEditEventModal"
    />
  </ModalComponent>

  <ModalComponent
    v-model="showDeleteEventModal"
    header="Delete Event"
  >
    <div>
      Are you sure you want to delete this event?
      <div class="mt-3 d-flex justify-content-end">
        <button
          @click="confirmDeleteEvent"
          class="btn btn-danger me-2"
        >
          Yes
        </button>
        <button
          @click="cancelDeleteEvent"
          class="btn btn-secondary"
        >
          No
        </button>
      </div>
    </div>
  </ModalComponent>
</template>
<script>
import { nextTick } from "vue";
import EventFormComponent from "./EventFormComponent.vue";
import { ref, onMounted, computed } from "vue";
import { auth, db } from "../firebase";
import { useRoute } from "vue-router";
import ROLES from "../utils/roles.json";
import { collection, getDocs, query, where, doc, getDoc, deleteDoc } from "firebase/firestore";
import ModalComponent from "./ModalComponent.vue";
import { useToast } from "vue-toastification";
import { getUserRole } from "../utils/auth";
import { onAuthStateChanged } from "firebase/auth";
export default {
  components: {
    EventFormComponent,
    ModalComponent,
  },
  props: {},
  setup(props) {
    const isLoading = ref(true);
    const activeTab = ref("squad");
    const team = ref({
      clubDetails: {
        logo: "",
        shortName: "",
      },
    });

    const club = ref({});
    const league = ref({});
    const showAddEventModal = ref(false);
    const showEditEventModal = ref(false);
    const currentEvent = ref(null);
    const teamEvents = ref([]);
    const route = useRoute();
    const toast = useToast();
    const userId = auth.currentUser.uid;
    const teamOwnerId = ref("");
    const userRole = ref("");
    const canCreate = ref(false);
    const canEdit = ref(false);
    const canDelete = ref(false);
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        userRole.value = await getUserRole(user);
        canCreate.value = ROLES.EVENT.CREATE.includes(userRole.value?.toLowerCase());
        canEdit.value = ROLES.EVENT.EDIT.includes(userRole.value?.toLowerCase());
        canDelete.value = ROLES.EVENT.DELETE.includes(userRole.value?.toLowerCase());
      }
    });

    const sortedTeamEvents = computed(() => {
      return teamEvents.value.sort((a, b) => a.date - b.date);
    });
    const isUserTeamOwner = computed(() => {
      return Array.isArray(teamOwnerId.value) ? teamOwnerId.value.includes(userId) : userId == teamOwnerId.value;
    });
    const formatYear = (input) => {
      if (input && typeof input.toDate === "function") {
        return input.toDate().getFullYear();
      } else if (input && typeof input === "string") {
        return input.split("-")[0];
      }
      return "";
    };

    const fetchData = async () => {
      try {
        const teamId = route.params.teamId;

        // Fetch team events
        const qTeamEvents = query(collection(db, "teamEvents"), where("teamId", "==", teamId));
        const teamEventsSnapshot = await getDocs(qTeamEvents);
        teamEvents.value = teamEventsSnapshot.docs.map((doc) => {
          return {
            id: doc.id,
            ...doc.data(),
          };
        });

        //Fetch team
        const teamDoc = await getDoc(doc(db, "teams", teamId));
        if (teamDoc.exists()) {
          team.value = teamDoc.data();
          teamOwnerId.value = teamDoc.data().ownerId;
        } else {
          console.error("No such team exists!");
        }

        isLoading.value = false;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const showDeleteEventModal = ref(false);
    const eventIdToDelete = ref(null);

    const deleteTeamEvent = (eventId) => {
      eventIdToDelete.value = eventId;
      showDeleteEventModal.value = true;
    };

    const confirmDeleteEvent = async () => {
      if (!eventIdToDelete.value) {
        console.error("No event ID provided for deletion.");
        return;
      }
      try {
        await deleteDoc(doc(db, "teamEvents", eventIdToDelete.value));
        showDeleteEventModal.value = false;

        toast.success("Event successfully deleted");

        // Refresh the events list
        await fetchData();
      } catch (error) {
        console.error("Error deleting event:", error);
        toast.error("Failed to delete event.");
      }
      eventIdToDelete.value = null;
    };

    const cancelDeleteEvent = () => {
      eventIdToDelete.value = null;
      showDeleteEventModal.value = false;
    };

    // Event handlers

    const refreshEventList = async () => {
      try {
        const q = query(collection(db, "teamEvents"), where("teamId", "==", props.teamId));

        const querySnapshot = await getDocs(q);

        teamEvents.value = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
      } catch (error) {
        console.error("Error fetching event:", error);
      }
    };

    const onEventAdded = () => {
      showAddEventModal.value = false;

      fetchData();
    };

    const onEventSaved = () => {
      showEditEventModal.value = false;

      fetchData();
    };

    const handleAddEvent = () => {
      showAddEventModal.value = true;
    };

    const handleCloseAddEventModal = () => {
      showAddEventModal.value = false;
    };

    const handleCloseEditEventModal = () => {
      showEditEventModal.value = false;
    };

    const editTeamEvent = (teamEvent) => {
      currentEvent.value = teamEvent;
      showEditEventModal.value = true;
    };

    const formatDate = (timestamp) => {
      if (timestamp && typeof timestamp.toDate === "function") {
        const dateObject = timestamp.toDate();
        return `${dateObject.getMonth() + 1}/${dateObject.getDate()}/${dateObject.getFullYear()}`;
      }
      return "";
    };

    onMounted(async () => {
      await fetchData(); // Call fetchData when the component mounts
    });

    return {
      activeTab,
      league,
      club,
      team,
      teamEvents,
      isLoading,
      sortedTeamEvents,
      formatYear,
      formatDate,
      showDeleteEventModal,
      deleteTeamEvent,
      confirmDeleteEvent,
      cancelDeleteEvent,
      showAddEventModal,
      showEditEventModal,
      currentEvent,
      editTeamEvent,
      onEventAdded,
      onEventSaved,
      handleAddEvent,
      handleCloseAddEventModal,
      handleCloseEditEventModal,
      refreshEventList,
      canCreate,
      canEdit,
      canDelete,
      userId,
      teamOwnerId,
      isUserTeamOwner,
    };
  },
};
</script>
<!-- STYLES -->

<style scoped lang="scss"></style>
